import Vue from 'vue'
import Router from 'vue-router'
import BaseLayout from '@/layout/BaseLayout.vue'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: BaseLayout,
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("@/views/Home"),
          meta: { title: "Home" }
        },
        {
          path: "about",
          component: () => import("@/views/About"),
          redirect:'about/message',
          children:[
            {
              path: "message",
              name: "message",
              component: () => import("@/views/About/components/Message.vue"),
              meta: { title: "Welcome Message" }
            },
            {
              path: "committee",
              name: "committee",
              component: () => import("@/views/About/components/Committee.vue"),
              meta: { title: "Local Organizing Committee" }
            },
            {
              path: "oc",
              name: "officer",
              component: () => import("@/views/About/components/Officer.vue"),
              meta: { title: "ASCVTS Officer and Councilors" }
            },
            {
              path: "intro",
              name: "intro",
              component: () => import("@/views/About/components/Introduction.vue"),
              meta: { title: "About ASCVTS 2024" }
            },
          ]
        },
        {
          path: "abstract",
          component: () => import("@/views/Abstract"),
          redirect:'abstract/oral',
          children:[
            {
              path: "oral",
              name: "oral",
              component: () => import("@/views/Abstract/components/Oral.vue"),
              meta: { title: "Oral Presentation" }
            },
            {
              path: "info",
              name: "abstractInfo",
              component: () => import("@/views/Abstract/components/Abstract.vue"),
              meta: { title: "For Oral/Poster" }
            },
            {
              path: "poster",
              name: "poster",
              component: () => import("@/views/Abstract/components/Poster.vue"),
              meta: { title: "Poster Exhibition" }
            },
          ]
        },
        {
          path:"/photo",
          name:"photoAlbum",
          component: () => import("@/views/PhotoAlbum"),
          meta: { title: "PhotoAlbum" }
        },
        {
          path: "/programs/glances",
          name: "programGlances",
          component: () => import("@/views/Programs/Glance"),
          meta: { title: "Program Glance" }
        },
        {
          path: "/programs/glance",
          name: "programGlance",
          component: () => import("@/views/Programs/pag.vue"),
          meta: { title: "Program Glance" }
        },
        {
          path: "/programs/scientific",
          name: "scientificProgram",
          component: () => import("@/views/Programs/sp"),
          meta: { title: "Scientific Program" }
        },
        {
          path: "/programs/guests",
          name: "invitedGuests",
          component: () => import("@/views/Programs/InvitedGuests"),
          meta: { title: "Guest Faculty" }
        },
        {
          path: "/programs/official",
          name: "officialSocialProgram",
          component: () => import("@/views/Programs/osp"),
          meta: { title: "Official & Social Program" }
        },
        {
          path: "/programs/president",
          name: "honoraryPresident",
          component: () => import("@/views/Programs/HPresident"),
          meta: { title: "Honorary President" }
        },
        {
          path: "abstract",
          component: () => import("@/views/Abstract"),
          redirect:'abstract/oral',
          children:[
            {
              path: "oral",
              name: "oral",
              component: () => import("@/views/Abstract/components/Oral.vue"),
              meta: { title: "Oral Presentation" }
            },
            {
              path: "poster",
              name: "poster",
              component: () => import("@/views/Abstract/components/Poster.vue"),
              meta: { title: "Poster Exhibition" }
            },
            {
              path: "posters",
              name: "posters",
              component: () => import("@/views/Abstract/components/PosterExhibition.vue"),
              meta: { title: "Poster Exhibition" }
            },
            {
              path: "orals",
              name: "orals",
              component: () => import("@/views/Abstract/components/OralPresentation.vue"),
              meta: { title: "Oral Presentation" }
            },
            {
              path: "info",
              name: "info",
              component: () => import("@/views/Abstract/components/Abstract.vue"),
              meta: { title: "For Oral/Poster" }
            }
          ]
        },
        {
          path: "/award",
          component: () => import("@/views/Award"),
          redirect:'award/aats',
          children:[
            {
              path: "aats",
              name: "aats",
              component: () => import("@/views/Award/components/Award.vue"),
              meta: { title: "Furuse & AATS Foundation Award" }
            },{
              path: "ors",
              name: "ors",
              component: () => import("@/views/Award/components/Ors.vue"),
              meta: { title: "\"Oriental Rising Star\" Award" }
            },
          ]
        },
        {
          path: "/accommodation",
          name: "accommodation",
          component: () => import("@/views/Accommodation"),
          meta: { title: "accommodation" }
        },
        {
          
          path: "/download",
          name: "download",
          component: () => import("@/views/Download"),
          meta: { title: "download" }
        },
        {
          path: "travel",
          component: () => import("@/views/Travel"),
          redirect:'travel/wuhan',
          children:[
            {
              path: "wuhan",
              name: "wuhan",
              component: () => import("@/views/Travel/components/Wuhan.vue"),
              meta: { title: "About Wuhan, China" }
            },
            {
              path: "venue",
              name: "venue",
              component: () => import("@/views/Travel/components/Venue.vue"),
              meta: { title: "Venue(The Wuhan International Expo Center)" }
            },
            {
              path: "transportation",
              name: "transportation",
              component: () => import("@/views/Travel/components/Transportation.vue"),
              meta: { title: "Transportation" }
            },
            {
              path: "reminder",
              name: "reminder",
              component: () => import("@/views/Travel/components/FriendlyReminder.vue"),
              meta: { title: "friendlyReminder" }
            },
          ]
        },
        // {
        //   path: "/user/sign",
        //   name: "sign",
        //   component: () => import("@/views/User/SignUp"),
        //   meta: { title: "SignUp" }
        // },
        // {
        //   path: "/user/login",
        //   name: "login",
        //   component: () => import("@/views/User/Login"),
        //   meta: { title: "Login" }
        // },
        {
          path: "/user/myPage",
          name: "myPage",
          component: () => import("@/views/User/MyPage"),
          meta: { title: "MyPage" }
        },
        {
          path: "/user/modifyInfo",
          name: "modifyInfo",
          component: () => import("@/views/User/ModifyInfo"),
          meta: { title: "ModifyInfo" }
        },
        {
          path: "/sponsors",
          name: "sponsors",
          component: () => import("@/views/Sponsors"),
          meta: { title: "Sponsors" }

        },
        {
          path: "/test",
          name: "test",
          component: () => import("@/views/Test/Index.vue"),
          meta: { title: "test" }
        },
        {
          path: "/tbd",
          name: "tbd",
          component: () => import("@/views/tbd"),
          meta: { title: "tbd" }
        },
        {
          path: "/sign",
          name: "sign",
          component: () => import("@/views/User/Sign.vue")
        },
        {
          path: "/privacyPolicy",
          name: "privacyPolicy",
          component: () => import("@/views/User/modules/PrivacyPolicy.vue"),
          meta: { title: "PrivacyPolicy" }
        },
        {
          path: "*",
          name: "404",
          component: () => import("@/views/404"),
          meta: { title: "404" }
        },
      ]
    },
  ]
});
