import axios from 'axios'
// import store from '@/store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import Vue from 'vue'
import {getAccessToken} from "@/utils/util";
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = getAccessToken()
  // 如果 token 存在
  if (token) {
    config.headers['Access-token'] = token
  }
  config.headers['meetingId'] = process.env.VUE_APP_ID
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
    return response.data
}, errorHandler)



const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}
export default request

export {
  installer as VueAxios,
  request as axios
}

/**
 * post
 * @param url
 * @param parameter
 * @returns {*}
 */
export function postAction(url, parameter) {
  return request({
    url: url,
    method: 'post',
    data: parameter
  })
}

/**
 * http
 * @param url
 * @param parameter
 * @param method: {post | put}
 * @returns {*}
 */
export function httpAction(url, parameter, method) {
  return request({
    url: url,
    method: method,
    data: parameter
  })
}

/**
 * put
 * @param url
 * @param parameter
 * @returns {*}
 */
export function putAction(url, parameter) {
  return axios({
    url: url,
    method: 'put',
    data: parameter
  })
}

/**
 * get
 * @param url
 * @param parameter
 * @returns {*}
 */
export function getAction(url, parameter) {
  return request({
    url: url,
    method: 'get',
    params: parameter
  })
}

/**
 * get with custom baseURL
 * @param url
 * @param parameter
 * @param baseURL
 * @returns {*}
 */
export function getActionWithBaseUrl(url, parameter, baseURL) {
  return request({
    baseURL: baseURL,
    url: url,
    method: 'get',
    params: parameter
  })
}

/**
 *
 * @param url
 * @param parameter
 * @param baseURL
 * @param headers 例：{'Content-Type': 'multipart/form-data'}
 * @returns {*}
 */
export function postActionWithBaseUrlAndHeaders(url, parameter, baseURL, headers) {
  const _headers = headers && typeof headers === 'object' ? headers : {}
  return request({
    baseURL: baseURL,
    url: url,
    data: parameter,
    method: 'post',
    headers: _headers,
  })
}

/**
 * delete
 * @param url
 * @param parameter
 * @returns {*}
 */
export function deleteAction(url, parameter) {
  return request({
    url: url,
    method: 'delete',
    params: parameter
  })
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter) {
  return request({
    url: url,
    params: parameter,
    method: 'get',
    responseType: 'blob',
  })
}

/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
export function downloadFile(url, fileName, parameter) {
  return downFile(url, parameter).then((data) => {
    if (!data || data.size === 0 || data.type === 'application/json') {
      console.log(data)
      Vue.prototype['$message'].warning('文件下载失败')
      return { code: 1, msg: 'error' };
    }
    if (data.fileName) {
      fileName = data.fileName.replace("+", " ");
    }
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(new Blob([data]), fileName)
    } else {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // 下载完成移除元素
      window.URL.revokeObjectURL(url) // 释放掉blob对象
    }
    return { code: 0, msg: 'ok' };
  })
}

/**
 * 文件上传 用于富文本上传图片
 * @param url
 * @param parameter
 * @returns {*}
 */
export function uploadAction(url, parameter) {
  return axios({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 6000,
    url: url,
    data: parameter,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data', // 文件上传
    },
  })
}

export function isMobile () {
  const mobileAgent = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  const mobileTouch = ('ontouchstart' in document.documentElement)
  const mobileMedia = window.matchMedia('only screen and (max-width: 825px)').matches
  return !!(mobileAgent || mobileTouch || mobileMedia);
}
