<template>
  <div class="base-layout">
    <LayoutHeader></LayoutHeader>
    <div style="position: sticky;top:0;z-index:11">
      <LayoutTabBar></LayoutTabBar>
    </div>
    <div class="base-view">
      <router-view></router-view>
    </div>
    <LayoutFooter></LayoutFooter>
    <div id="components-back-top-demo-custom">
      <a-back-top>
        <div class="ant-back-top-inner">
          <span class="iconfont icon-icon-arrow-double-up"></span>
        </div>
      </a-back-top>
    </div>
  </div>
</template>

<script>

import LayoutHeader from './components/LayoutHeader.vue'
import LayoutFooter from './components/LayoutFooter.vue'
import LayoutTabBar from './components/LayoutTabBar.vue'

export default {
  name:'BaseLayout',
  components:{
    LayoutHeader,
    LayoutTabBar,
    LayoutFooter
  }
}
</script>

<style lang="less" scoped>
.base-layout {
  font-family: "Akrobat Black";
  // font-family: "Akrobat SemiBold";
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .base-view {
    flex: 1;
  }
  #components-back-top-demo-custom .ant-back-top {
    bottom: 20px;
    right:60px;
  }
  #components-back-top-demo-custom .ant-back-top-inner {
    height: 45px;
    width: 45px;
    line-height: 45px;
    border-radius: 4px;
    background-color: #233873;
    color: #e59200;
    text-align: center;
    .iconfont {
      font-size: 32px;
    }
  }
}
</style>
