<template>
  <div class="site-navigation">
    <div class="navbar-collapse" :class="[menuSwitch,{'auto':tabSwitch}]">
      <div class="navbar-toggler">
        <div class="menu" @click="handleClick">
          <span class="iconfont icon-menu"></span>
        </div>
        <div class="navbar-title"><h3>ASCVTS 2024 WUHAN</h3></div>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item dropdown" :class="{ 'actived': urlPath.includes('about') }">
          <a
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            >ASCVTS 2024 <i class="iconfont icon-arrowdown" @click="iconClick"></i></a>
          <ul class="dropdown-menu" role="menu">
            <li v-for="(nav, index) in aboutNav" :key="index" :class="{ 'actived': urlName.includes(nav.urlName) }"><a @click="redirectUrl(nav)">{{ nav.title }}</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown" :class="{ 'actived': urlPath.includes('programs') }">
          <a
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            >PROGRAMS<i class="iconfont icon-arrowdown" @click="iconClick"></i></a>
          <ul class="dropdown-menu" role="menu">
            <li v-for="(nav, index) in programNav" :key="index" :class="{ 'actived': urlName.includes(nav.urlName) }"><a @click="redirectUrl(nav)">{{ nav.title }}</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown" :class="{ 'actived': urlPath.includes('abstract') }">
          <a
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            >ABSTRACT<i class="iconfont icon-arrowdown" @click="iconClick"></i></a>
          <ul class="dropdown-menu" role="menu">
            <li :class="{ 'actived': urlName.includes('info') }"><a @click="$router.push({name: 'info'})">For Oral/Poster</a></li>
            <li><a href="https://mm.sciconf.cn/en/web/abstract-search/21452" target="_black">Results of Submission</a></li>
          </ul>
        </li>
        <!-- <li class="nav-item" :class="{ 'actived': urlPath.includes('info') }">
          <a class="nav-link" @click="$router.push({name: 'info'})">ABSTRACT</a>
        </li> -->
        <li class="nav-item dropdown" :class="{ 'actived': urlPath.includes('award') }">
          <a
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            >AWARDS & GRANTS<i class="iconfont icon-arrowdown" @click="iconClick"></i></a>
          <ul class="dropdown-menu" role="menu">
            <li v-for="(nav, index) in awardNav" :key="index" :class="{ 'actived': urlName.includes(nav.urlName) }"><a @click="redirectUrl(nav)">{{ nav.title }}</a></li>
          </ul>
        </li>
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            >Registration <i class="iconfont icon-arrowdown" @click="iconClick"></i></a>
          <ul class="dropdown-menu" role="menu">
            <li>
              <a >Registration (For Overseas)</a
              >
            </li>
            <li>
              <a >Registration (For Domestic)</a
              >
            </li>
            <li>
              <a >Visa Requirements</a
              >
            </li>
          </ul>
        </li> -->
        <li class="nav-item" :class="{ 'actived': urlPath.includes('accommodation') }">
          <a class="nav-link" @click="$router.push({name: 'accommodation'})">ACCOMMODATION</a>
        </li>
        <li class="nav-item" :class="{ 'actived': urlPath.includes('sponsors') }">
          <a class="nav-link" @click="$router.push({name: 'sponsors'})">SPONSORS</a>
        </li>
        <li class="nav-item dropdown" :class="{ 'actived': urlPath.includes('travel') }">
          <a
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            >TRAVEL INFORMATION<i class="iconfont icon-arrowdown" @click="iconClick"></i></a>
          <ul class="dropdown-menu" role="menu">
            <li v-for="(nav, index) in travelInfo" :key="index" :class="{ 'actived': urlName.includes(nav.urlName) }"><a @click="redirectUrl(nav)">{{ nav.title }}</a></li>
          </ul>
        </li>
        <li class="nav-item" :class="{ 'actived': urlPath.includes('download') }">
          <a class="nav-link" @click="$router.push({name: 'download'})">DOWNLOAD</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutTabBar",
  data() {
    return {
      flag: false,
      tabSwitch:false,
      menuSwitch: '',
      aboutNav: [
        {title: "Welcome Message", urlName: "message" },
        {title: "Local Organizing Committee", urlName: "committee" },
        {title: "ASCVTS Officer and Councilors", urlName: "officer" },
        {title: "About ASCVTS 2024", urlName: "intro" },
        {title: "Photo Gallery", urlName: "photoAlbum" },
      ],
      abstract:[
        {title: "Oral Presentation", urlName: "oral" },
        {title: "Poster Exhibition", urlName: "poster" },
        {title: "For Oral/Poster", urlName: "details" },
      ],
      programNav: [
        {title: "Program at a Glance", urlName: "programGlance" },
        {title: "Scientific Program", urlName: "scientificProgram" },
        {title: "Honorary President", urlName: "honoraryPresident" },
        {title: "Guest Faculty", urlName: "invitedGuests" },
        {title: "Social & Official Program", urlName: "officialSocialProgram" },
      ],
      abstractNav: [
        {title: "Oral Presentation", urlName: "oral" },
        {title: "Poster Exhibition", urlName: "poster" },
        {title: "For Oral/Poster", urlName: "info" }
      ],
      awardNav: [
        {title: "Furuse & AATS Foundation Award", urlName: "aats" },
        {title: "\"Oriental Rising Star\" Award", urlName: "ors" }
      ],
      travelInfo: [
        {title: "About Wuhan, China", urlName: "wuhan" },
        {title: "Venue (Wuhan)", urlName: "venue" },
        {title: "Transportation", urlName: "transportation" },
        {title: "Friendly Reminder", urlName:"reminder" }
      ]
    };
  },
  methods:{
    redirectUrl(nav) {
      if (nav.urlName === "programGlance") {
        return window.open("https://mm.sciconf.cn/en/minisite/program-view/21452","Program at a Glance");
      }
      if (nav.urlName === "scientificProgram") {
        return window.open("https://ascvts2024.sciconf.cn/en/web/program/21452");
      }
      if (nav.urlName === "invitedGuests") {
        return window.open("https://ascvts2024.sciconf.cn/en/web/speaker/21452");
      }
      this.$router.push({name: nav.urlName})
    },
    handleClick(){
      if ( this.flag ) return
      if ( this.menuSwitch !== 'expanded' ) {
        this.menuSwitch = 'expanded'
      } else {
        this.menuSwitch = 'collapsed'
      }
      this.flag = true
      this.tabSwitch = false
      if ( this.menuSwitch !== 'expanded') {
        document.querySelector('.active')?.classList.remove('active')
      }
      setTimeout(() => {
        this.flag = false
        this.tabSwitch = this.menuSwitch === 'expanded'
      }, 500)
    },
    iconClick(e){
      const ele = e.target.parentElement.parentElement
      if ( ele.classList.contains('active') ) {
        ele.classList.remove('active')
      } else {
        document.querySelector('.active')?.classList.remove('active')
        ele.classList.add('active')
      }
    }
  },
  computed: {
    urlPath(){
      return this.$route.path
    },
    urlName(){
      return this.$route.name
    }
  }
}
</script>

<style lang="less" scoped>
  .site-navigation {
    background-color: #19317e;
    .navbar-collapse {
      a {
        cursor: pointer;
      }
      max-width: 1320px;
      margin: 0 auto;
      transform: .4s;
      .navbar-toggler {
        display: none;
      }
      .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        .nav-item {
          position: relative;
          @media (min-width:992px) {
            &:hover {
              .dropdown-menu {
                display: block;
              }
              .nav-link {
                color: #f8b301;
              }
            }
            .dropdown-menu {
              display: none;
              position: absolute;
              top: 100%;
              background-color: #f8f9fa;
              border-left: 1px solid #ddd;
              border-right: 1px solid #ddd;
              border-bottom: 1px solid #ddd;
              border-top: 2px solid #F8B301;
              z-index: 1;
              padding: 5px 10px;
              min-width: 250px;
              li {
                &:last-child  a{
                  border-bottom: 0;
                }
                a {
                  display: block;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: normal;
                  text-decoration: none;
                  padding: 10px;
                  border-bottom: 1px solid #e5e5e5;
                  color: #333333;
                  transition: color .3s;
                }
                &:hover a{
                  color: #f8b301;
                }
              }

            }
          }
          .nav-link {
            display: block;
            padding: 15px;
            font-size: 16px;
            line-height: 40px;
            font-weight: 600;
            color: #fff;
            transition: color .3s;
          }
          &.actived {
            .nav-link {
              color: #f8b301;
            }
          }
          .dropdown-menu li.actived a{
            color: #f8b301;
          }
        }
      }
      @media (max-width:992px) {
        overflow: hidden;
        height: 58px;
        &.collapsed {
          height: 58px;
          animation: collapsedAnimation 0.5s ease;
        }
        &.expanded {
          height: 367px;
          animation: expandedAnimation 0.5s ease;
        }
        &.auto {
          height: auto;
        }
        .navbar-toggler {
          display: flex;
          justify-content: space-between;
          height: 58px;
          color: #fff;
          padding: 10px 15px;
          .menu {
            height: 38px;
            width: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #F8B301;
            cursor: pointer;
            .iconfont {
              font-size: 20px;
            }
          }
          .navbar-title {
            line-height: 38px;
            font-size: 28px;
            h3 {
              color: #fff;
            }
          }
        }
        .navbar-nav {
          display: block;
          padding-bottom: 15px;
          .nav-item  {
            .nav-link {
              padding: 0 15px;
              line-height: 42px;
              display: flex;
              justify-content: space-between;
              .iconfont  {
                width: 28px;
                height: 28px;
                margin-top: 7px;
                line-height: 28px;
                background-color: rgba(0,0,0,.6);
                text-align: center;
              }
            }
            .dropdown-menu {
              display: none;
              top: 100%;
              padding: 0 15px;
              li {
                background-color: #f8f9fa;
                &:last-child  a{
                  border-bottom: 0;
                }
                a {
                  display: block;
                  font-size: 14px;
                  line-height: normal;
                  text-decoration: none;
                  padding: 8px 10px;
                  border-bottom: 1px solid #e5e5e5;
                  color: #444;
                  transition: color .3s;
                }
              }

            }
            &.active {
              .nav-link {
                color: #F8B301;
              }
              .dropdown-menu {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  @keyframes collapsedAnimation {
    0% {
      height: 352px;
    }
    100% {
      height: 58px;
    }
  }
  @keyframes expandedAnimation {
    0% {
      height: 58px;
    }
    100% {
      height: 367px;
    }
  }
</style>
