<template>
  <footer id="footer" class="footer">
    <div class="footer-widget">
      <div class="footer-left">
        <h3 class="widget-title">ASCVTS 2024 Wuhan Secretariat</h3>
        <p>
          President, Organizing Committee: Nianguo Dong<br/>
          Address: Jiefang Road 1277, Jianghan District, Wuhan, 430000, P.R. China<br/>
          E-mail: <a>ASCVTS2024WUHAN@hotmail.com</a> <br/>
          China Medicine Education Association <br/>
          <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #aaa;">京ICP备15021671号-3</a>
        </p>
      </div>
       <div class="footer-right">
<!--        <a class="btn btn-left">Terms of Use</a>-->
        <a class="btn btn-right" @click="$router.push({name: 'privacyPolicy'})">Privacy Policy</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name:'LayoutFooter',
  data(){
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  background-color: #19317e;
  .footer-widget {
    max-width: 1320px;
    margin: 0 auto;
    padding: 20px 15px;
    color: #aaa;
    display: flex;
    flex-wrap: wrap;
    .footer-left {
      flex:1;
      .widget-title {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        position: relative;
      }
    }
    .footer-right {
      text-align: right;
      .btn {
        cursor: pointer;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 1.5;
        border-radius: 3px;
        &.btn-left {
          background-color: #e59200;
          color: #333;
          margin-right: 1em;
        }
        &.btn-right {
          background-color: #0971ce;
          color: #fff;
        }
      }
    }
    @media (max-width: 768px) {
      .footer-right {
        width: 100%;
        margin-top: 10px;
        text-align: left;
      }
    }
  }
}
</style>
