<template>
  <div id="app">
    <router-view :key="key" />
  </div>
</template>

<script>

export default {
  name: 'App',
  computed: {
    key() {
      return this.$route.name ? this.$route.name + +new Date() : this.$route + +new Date()
    }
  }
}
</script>

<style>
#app,html,body {
  height: 100%;
}
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
</style>
