import { isNullOrUndefined } from '@/utils/util'

const fix = ''

/**
 * 存储介质适配器
 * @param isSessionStorage
 * @returns
 */
const cacheAdapter = (isSessionStorage) => {
  return isSessionStorage ? sessionStorage : localStorage;
}

/**
 * 取缓存值
 * @param {*} key
 * @param {*} options: {isParse: false, isDelete: false, isSessionStorage: true}
 * @param defaultValue
 */
export const getCache = (key, options, defaultValue) => {
  key = fix + key;
  options = { isParse: true, isDelete: false, isSessionStorage: false, ...options };
  try {
    const value = cacheAdapter(options.isSessionStorage).getItem(key);
    if (options.isDelete) {
      cacheAdapter(options.isSessionStorage).removeItem(key);
    }
    return isNullOrUndefined(value)
      ? defaultValue
      : options.isParse
        ? value
          ? JSON.parse(value)
          : defaultValue
        : value;
  } catch (error) {
    console.error('getCache', error);
    return defaultValue;
  }
}

/**
 * 设置缓存值
 * @param {*} key
 * @param {*} value
 * @param isSessionStorage: default false
 */
export const setCache = (key, value, isSessionStorage = false) => {
  key = fix + key;
  cacheAdapter(isSessionStorage).setItem(
    key,
    typeof value === 'object' ? JSON.stringify(value) : value
  );
};

/**
 * 设置会过期缓存
 * @param key
 * @param value
 * @param expire 过期时间(单位：ms)
 * @param options
 */
export const setExpireCache = (key, value, expire, options) => {
  key = fix + key;
  options = { isSessionStorage: false, ...options };
  cacheAdapter(options.isSessionStorage).setItem(
    key,
    JSON.stringify({
      value,
      expire: new Date().getTime() + expire
    })
  );
};

/**
 * 取会过期缓存值
 * @param key
 * @param options: {isParse: false, isDelete: false, isSessionStorage: true}
 * @param defaultValue
 */
export const getExpireCache = (key, options, defaultValue) => {
  key = fix + key;
  options = { isParse: false, isDelete: false, isSessionStorage: false, ...options };
  try {
    const value = cacheAdapter(options.isSessionStorage).getItem(key);
    if (isNullOrUndefined(value)) return defaultValue;
    const cacheValue = JSON.parse(value);
    // 过期处理
    if (cacheValue.expire < new Date().getTime()) {
      cacheAdapter(options.isSessionStorage).removeItem(key);
      return defaultValue;
    }
    if (options.isDelete) {
      cacheAdapter(options.isSessionStorage).removeItem(key);
    }
    const data = cacheValue.value;
    return isNullOrUndefined(data) ? defaultValue : options.isParse ? data ? JSON.parse(data) : defaultValue : data;
  } catch (error) {
    console.error('getCache', error);
    return defaultValue;
  }
}

/**
 * 删除Storage
 * @param key
 * @param isSessionStorage
 */
export const removeStorage = (key, isSessionStorage) => {
  if (key) {
    cacheAdapter(isSessionStorage).removeItem(key)
  }
}
