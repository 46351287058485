import {getExpireCache, removeStorage, setExpireCache} from "@/utils/cache";
import {ACCESS_TOKEN, USER_INFO} from "@/types/mutation-types";


/**
 * 移动端验证
 * @returns {boolean}
 */
export function isMobile() {
  const mobileAgent = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  const mobileTouch = ('ontouchstart' in document.documentElement)
  const mobileMedia = window.matchMedia('only screen and (max-width: 825px)').matches
  return !!(mobileAgent || mobileTouch || mobileMedia)
}

/**
 * 判断设备屏幕宽度是否小于768px
 * @returns {boolean}
 */
export function screenWidthIsLe768() {
  return window.matchMedia('(max-width: 767px)').matches
}

/**
 * 是否只null和undefined值
 * @param vl
 * @returns
 */
export function isNullOrUndefined (vl) {
  return vl === null || typeof vl === 'undefined'
}

/**
 * 获取用户登录token
 * @returns {any | undefined}
 */
export function getAccessToken() {
  return getExpireCache(ACCESS_TOKEN)
}


/**
 * 获取用户信息
 * @returns {any | undefined}
 */
export function getMemberInfo() {
  return getExpireCache(USER_INFO)
}

/**
 * 设置用户信息
 * @returns {any | undefined}
 */
export function setMemberInfo(userInfo) {
  setExpireCache(USER_INFO, userInfo, 30 * 3600 * 24 * 1000 )
}

/**
 * 登录保存用户信息
 * @returns {any | undefined}
 */
export function setLoginInfo(userInfo, token) {
  setMemberInfo(userInfo)
  setExpireCache(ACCESS_TOKEN, token, 30 * 3600 * 24 * 1000 )
}

/**
 * 判断是否登录
 */
export function isLogin() {
  const token = getAccessToken();
  if (token) {
    return true
  }else{
    logout()
    return false
  }
}

/**
 * 退出登录
 */
export function logout() {
  removeStorage(ACCESS_TOKEN)
  removeStorage(USER_INFO)
}

// 元素是否在窗口可见
export function isElementInViewport (el) {
  var rect = el.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

/**
 * formModal 表单提示和自动滚动到指定位置
 * @param {*} $form this.$refs.form
 */
export function formModalWarnAndScroll ($form) {
  let $fields = $form.fields
  let $errorItem = $fields.find(item=> {
    return item.validateState === 'error'
  })
  if ($errorItem){
    $errorItem.$el.scrollIntoView({block: "center"})
    //scrollIntoView()
  }
}

/**
 *
 * @returns {number}
 */
export function getCurrentSiteId() {
  return 1
}

/**
 * 操作系统类型
 * @type {{Windows: number, IOS: number, Mac: number, Android: number}}
 */
export const OS_TYPE = {
  Unknown: 5,
  Windows: 0,
  Mac: 1,
  Android: 2,
  IOS: 3,
  Linux: 4
}

/**
 * 设备类型
 * @type {{App: number, PC: number, Weixin: number, Server: number, Web: number, Miniprogram: number}}
 */
export const DEVICE_TYPE = {
  Unknown: -1,
  App: 0,
  Web: 1,
  Weixin: 2,
  Miniprogram: 3,
  Server: 4,
  PC: 5
}

/**
 * 获取操作系统类型
 */
export const getOSType = () => {
  //首先获取设备类型
  const agent = navigator.userAgent.toLowerCase()
  if (!agent) return OS_TYPE.Unknown
  if (agent.indexOf('windows') > -1) {
    return OS_TYPE.Windows
  } else if (agent.indexOf('iphone') > -1 || agent.indexOf('ipad') > -1) {
    return OS_TYPE.IOS
  } else if (agent.indexOf('mac') > -1) {
    return OS_TYPE.Mac
  } else if (agent.indexOf('android') > -1) {
    return OS_TYPE.Android
  } else if (agent.indexOf('linux x86_64') > -1) {
    return OS_TYPE.Linux
  }
  return OS_TYPE.Unknown
}

/**
 * 获取设备类型
 */
export const getDeviceType = async (itemUrl) => {
  const agent = navigator.userAgent.toLowerCase()
  if ((itemUrl && itemUrl.indexOf('webview=1') > -1)) {
    return DEVICE_TYPE.App
  } else if (itemUrl && itemUrl.indexOf('||') > -1) {
    return DEVICE_TYPE.Miniprogram
  }else if (agent.indexOf('micromessenger') !== -1) {
    return DEVICE_TYPE.Weixin
  }
  return DEVICE_TYPE.Web
}
