import router from './router'

import { isLogin } from '@/utils/util'
import { insertVisitRecord } from "@/api/visit";


const needLoginList = ['myPage', 'modifyInfo'];

router.beforeEach(async (to, from, next) => {
  if ( !isLogin() ) {
    if ( needLoginList.includes(to.name) ) {
      next({ name:'login',params: to.params })
    }
  }
  next();
})

router.afterEach( (to, from) => {
  window.scrollTo(0, 0);
  insertVisitRecord(to, from);
})
