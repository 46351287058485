<template>
  <header id="header" class="header-box">
    <div class="logo-border">
      <div class="logo-header">
        <h1 class="logo">
          <a href="/">
            <img loading="lazy" src="@/assets/img/layout/logo_2024.png" alt="ASCVTS2024" />
          </a>
        </h1>
        <div class="text-right">
          <div>
            <a href="https://www.whuh.com/en/" target="_blank">
              <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/09/19/637736a4ec0243468fc04186c725f3ef.png"/>
            </a>
          </div>
          <div>
            <a href="https://ascvts.org/" target="_blank">
              <img src="@/assets/img/layout/logo_ass02.png"/>
            </a>
          </div>
          <div>
            <a href="https://ascvts.org/" target="_blank">
              <img src="@/assets/img/layout/logo_ass01.png"/>
            </a>
          </div>
        </div>
        <div class="text-center">
          <h2 class="top-title quote">
            <span class="text-gradient-1">Cooperation, Innovation, Excellence</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="top-bar">
      <ul class="divide-x flex items-center" v-if="!isLogin">
        <li class="inline-block text-sm px-4 cursor-pointer" @click="$router.push({name: 'home'})">HOME</li>
        <li class="inline-block text-sm px-4 cursor-pointer" @click="$router.push({name: 'sign'})">SIGN UP</li>
        <li class="inline-block text-sm px-4 cursor-pointer" @click="goLogin('https://ascvts2024.sciconf.cn/en/user/login/21452')">LOGIN</li>
      </ul>
      <ul class="divide-x flex items-center" v-else>
        <li class="inline-block text-sm px-4 cursor-pointer" @click="$router.push({name: 'home'})">HOME</li>
        <li class="inline-block text-sm px-4 cursor-pointer" @click="$router.push({name: 'myPage'})">MY PAGE</li>
        <li class="inline-block text-sm px-4 cursor-pointer" @click="logout">LOGOUT</li>
      </ul>
      <div class="info-text text-sm" >
        <a-icon class="icon-font" type="sound" theme="filled" />
        <div class="info-text-rt">
          <div class="slide-box">
            <!-- <a href="https://ascvts2024.sciconf.cn/cn/user/login/21452" target="_blank">
              <div class="typed-out cursor-pointer">
                 Conference Registration Is Now Open！
              </div>
            </a> -->
            <!-- <div class="typed-out-s cursor-pointer">
              Registered and paid foreign guests can download conference certificates from the personal center on the official website of the conference！
            </div> -->
            <!-- <div class="typed-out-t cursor-pointer">
              Please book your own accommodation in time！
            </div> -->
            <div class="typed-out">
              <span>Registered and paid foreign guests can download conference certificates from the personal center on the official website of the conference！</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {isLogin, logout} from "@/utils/util";

export default {
  name:'LayoutHeader',
  data(){
    return {
      isLogin: isLogin()
    }
  },
  methods: {
    logout() {
      logout()
      const url = this.$router.resolve({name: "home"})
      window.location.href = url.href
    },
    goLogin(url){
      window.location.href = url
    }
  }
}
</script>

<style lang="less" scoped>
.header-box {
  background: #f4f5f7;
  .logo-border {
    border-bottom: 1px solid #DEE2E6;
  }
  .logo-header {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 15px;
    .logo  {
      flex: 0 0 20%;
      order: 1;
      margin: 0;
      img {
        height: 70px;
      }
    }
    .text-center {
      flex: 60%;
      order: 2;
      padding: 0 15px;
      position: relative;
      .top-title {
        font-size: 29px;
        text-align: center;
        font-style: italic;
        letter-spacing: -1px;
        margin: 0;
        position: relative;
        .text-gradient-1 {
          background: linear-gradient(73deg, #bc395c 20%, #3e437d 100%);
          color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
          position: relative;
          padding: 0 10px;
          &::before {
            content: '“';
            color: #ea5350;
            font-size: 29px;
            position: absolute;
            top: -10px;
            left: 0px;
            transform: translateX(-100%);
          }
          &::after {
            content: '”';
            color: #ea5350;
            font-size: 29px;
            position: absolute;
            top: 10px;
            right: 0px;
            transform: translateX(100%);
          }
        }
      }
    }
    .text-right {
      display: flex;
      flex: 0 0 20%;
      order: 3;
      text-align: right;
      flex-direction: row-reverse;
      img {
        width: 70px;
        height: 70px;
      }
    }
    @media (max-width: 1120px) {
      .logo {
        flex: 0 0 50%;
      }
      .text-center {
        flex:0 0 100%;
        order: 4;
        margin-top: 10px;
      }
      .text-right {
        flex: 0 0 50%;
      }
    }
    @media (max-width: 576px) {
      .logo img {
        height: 50px;
      }
      .text-center {
        .top-title {
          font-size: 16px;
          // .text-gradient-1 {
          //   display: block;
          //   width: 100%;
          // }
        }
      }
      .text-right img {
        width: 50px;
        height: 50px;
      }
    }
  }
  .top-bar {
    max-width: 1320px;
    margin: 0 auto;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    .icon-font {
      color: #d9534f;
    }
    .info-text {
      flex: 1;
      height: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      .info-text-rt {
        flex: 1;
        height: 20px;
        .slide-box {
          max-width: 600px;
          height: 20px;
          overflow: hidden;
          position: relative;
          a {
            position: absolute;
          }
          .typed-out {
            position: absolute;
            font-size: 1rem;
            white-space: nowrap;
            animation: slideLeftPC 15s infinite;
            animation-timing-function: linear;
            color: #d9534f;
          }
        }
      }
    }
    @keyframes slideLeftPC {
      0% {
          transform: translateX(600px);
      }
      100% {
          transform: translateX(-100%);
      }
    }
    @keyframes slideLeftMB {
      0% {
          transform: translateX(100vw);
      }
      100% {
          transform: translateX(-100%);
      }
    }
    @media (max-width: 768px) {
      display: block;
      padding: 0px 10px 0;
      .info-text {
        .info-text-rt {
          .slide-box {
            .typed-out {
              position: absolute;
              font-size: 1rem;
              white-space: nowrap;
              animation: slideLeftMB 15s infinite;
              animation-timing-function: linear;
              color: #d9534f;
            }
          }
        }
      }
    }
  }
}
</style>
